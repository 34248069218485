import React from "react";
import { config, useSpring } from "react-spring";
import Layout from "../components/layout";
import { AnimatedBox } from "../elements";
import SEO from "../components/SEO";
import Portrait from "../components/portrait";

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Layout color="#131728">
      <SEO title="About | Hannele" desc="Hi. I'm Hannele Lundy!" />
      <AnimatedBox
        style={pageAnimation}
        py={[6, 6, 6, 8]}
        px={[6, 6, 8, 6, 8, 13]}
      >
        <div>
          <Portrait />
          <h1 style={{ alignSelf: "center" }}>Hi. I'm Hannele Lundy!</h1>

          <div />
        </div>
        <p>I am a North Carolina based Fine Art Photographer.</p>
        <p>
          I fell in love with photography and photo manipulation due to the
          limitless possibilities of the medium. Photography started as a way to
          express myself but has evolved into deeper visual narratives to ignite
          emotions and wonder in a surreal atmosphere.
        </p>
        <p>
          My work is often inspired by my own life experiences but also by
          poetry, short stories, paintings and nature. Each image starts as a
          concept sketch that undergoes several revisions before the idea speaks
          to me. From there I decide which components of the image should be a
          physical effect in the photograph and which will be manipulated into
          the photo.
        </p>
        <p>
          The finishing touches to my creations are textures and brushes applied
          to yield a surreal and painterly look. My visual style is often dark
          and moody with a surreal twist, which is completely different from my
          personality. I feel very connected with darkness because it has always
          been part of my life and through art I found a way to channel it into
          something beautiful.
        </p>
        <h3>Exhibitions &amp; Competitions</h3>
        <p>
          PhotoPlace Gallery, Dreamscapes and Visions: "Acceptance" June 2020
        </p>
        <p>
          PhotoPlace Gallery,{" "}
          <a href="https://photoplacegallery.com/online-juried-shows/dreamscapes-and-visions/">
            Dreamscapes and Vision Online Gallery
          </a>
          : "Hidden" 2020
        </p>
        <p>
          Boynes Emerging Artist Award, 3rd Edition Finalist: "Behind the Frame" November 2020
        </p>
      </AnimatedBox>
    </Layout>
  );
};

export default About;
