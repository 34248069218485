import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "../types";
import Img from "gatsby-image";

type PageProps = {
  logoImage: ChildImageSharp;
};

const Portrait = () => {
  const props: PageProps = useStaticQuery(query);
  return (
    <Img
      fixed={props.logoImage.childImageSharp.fixed}
      imgStyle={{ borderRadius: "50%" }}
    />
  );
};

export default Portrait;

export const query = graphql`
  query PortraitImageQuery {
    logoImage: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "portrait" }
    ) {
      childImageSharp {
        fixed(quality: 95, width: 125) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
